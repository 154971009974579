import React from 'react';
import { CloseBtn, Container, Description, TextWrapper, Title } from './ModalHeader.styled';
import CloseSvg from '@/icons/close.svg';
import { RootState } from '@/redux/rootReducer';
import { useSelector } from 'react-redux';
import { Colors } from '@/style/colors';

export interface ModalHeaderProps {
    title?: string;
    description?: string;
    onCloseClick: (e: React.SyntheticEvent) => void;
    borderColored?: boolean;
    image?: boolean;
    bgColor?: Colors;
    onMaxWidth?: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, description, onCloseClick, borderColored, image, bgColor, onMaxWidth }) => {
    const { altDescription } = useSelector((state: RootState) => state.modal);

    return (
        <Container borderColored={borderColored} image={image} bgColor={bgColor}>
            <TextWrapper onMaxWidth={onMaxWidth}>
                {!!title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
                {!altDescription?.text && description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
                {altDescription?.text && <Description dangerouslySetInnerHTML={{ __html: altDescription.text }} />}
            </TextWrapper>
            <CloseBtn image={image} onClick={onCloseClick}>
                <CloseSvg />
            </CloseBtn>
        </Container>
    );
};

export default ModalHeader;
